import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";

import {
  AddCircleOutline,
  DeleteOutline,
  Edit,
  Search
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((u) => u.empfantasia === message.empfantasia);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...state, ...newMessages];
  }
  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const ReportMessages = () => {
  const classes = useStyles();
  const dataAtual = new Date();
  const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
  const ano = dataAtual.getFullYear().toString();

  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState( mes + '/' + ano);
  const [messages, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    if(searchParam.length == 7){
    dispatch({ type: "RESET" });
  }
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      if(searchParam.length == 7){
      const fetchMessages = async () => {
        try {
          const { data } = await api.post("/countMessages/", {searchParam
          });
          dispatch({ type: "LOAD_MESSAGES", payload: data.data });
          
        } catch (err) {
          toastError(err);
        }
        
      };
   
      fetchMessages();
    }
    setLoading(false);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  const handleSearch = (event) => {
    const cleanedValue = event.target.value.replace(/\D/g, '');
    const maxLength = 6;
    let firstDigits = cleanedValue.slice(0, 2);
    if (parseInt(firstDigits) > 12) {
      firstDigits = '12';
    }
    if (firstDigits == '00') {
      firstDigits = '01';
    }
    const remainingDigits = cleanedValue.slice(2, maxLength);
    let maskedValue = firstDigits;
      if (remainingDigits.length > 0) {
        maskedValue += '/' + remainingDigits;
      }
    setSearchParam(maskedValue.toLowerCase());
    
  };
  return (
    <MainContainer>
 
      <MainHeader>
        <Title>{i18n.t("reportMessages.title")} ({messages.length})</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("reportMessages.table.codBS")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("reportMessages.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("reportMessages.table.QtdMsg")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <> 
              {messages.map((message) => (
               
                <TableRow key={message.MENS_REMETENTE}>
                  <TableCell align="center">{message.MENS_REMETENTE}</TableCell>
                  <TableCell align="center">{message.empfantasia}</TableCell>
                  <TableCell align="center">{message.total_mensagens_enviadas}</TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default ReportMessages;
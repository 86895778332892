import React, { useState, useEffect, useReducer, useCallback } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";

import {
  AddCircleOutline,
  DeleteForever,
  DeleteOutline,
  Edit,
  Search
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import HideMessagesModal from "../../components/HideMessagesModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_HIDEMESSAGES") {
    const hideMessages = action.payload;
    const newHideMessages = [];
    if(hideMessages?.length > 0){
    hideMessages.forEach((hideMessage) => {
      const hideMessageIndex = state.findIndex((s) => s.id === hideMessage.id);
      if (hideMessageIndex !== -1) {
        state[hideMessageIndex] = hideMessage;
      } else {
        newHideMessages.push(hideMessage);
      }
    });
  }
    return [...state, ...newHideMessages];
  }

  if (action.type === "UPDATE_HIDEMESSAGES") {
    const hideMessage = action.payload;
    const hideMessageIndex = state.findIndex((s) => s.id === hideMessage.id);

    if (hideMessageIndex !== -1) {
      state[hideMessageIndex] = hideMessage;
      return [...state];
    } else {
      return [hideMessage, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const hideMessageId = action.payload;

    const hideMessageIndex = state.findIndex((s) => s.id === hideMessageId);
    if (hideMessageIndex !== -1) {
      state.splice(hideMessageIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const HideMessages = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedHideMessage, setSelectedHideMessage] = useState(null);
  const [deletingHideMessage, setDeletingHideMessage] = useState(null);
  const [deletingAllHideMessages, setDeletingAllHideMessages] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [hideMessages, dispatch] = useReducer(reducer, []);
  const [hideMessageModalOpen, setHideMessageModalOpen] = useState(false);

  const fetchHideMessages = useCallback(async () => {
    try {
      const { data } = await api.get("/hideMessages");
      dispatch({ type: "LOAD_HIDEMESSAGES", payload: data});
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchHideMessages();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchHideMessages]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("hideMessages", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_HIDEMESSAGES", payload: data.hideMessages });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_HIDEMESSAGES", payload: +data.hideMessageId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenHideMessageModal = () => {
    setSelectedHideMessage(null);
    setHideMessageModalOpen(true);
  };

  const handleCloseHideMessageModal = () => {
    setSelectedHideMessage(null);
    setHideMessageModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditHideMessage = (hideMessage) => {
    setSelectedHideMessage(hideMessage);
    setHideMessageModalOpen(true);
  };

  const handleDeleteHideMessage = async (hideMessageId) => {
    try {
      await api.delete(`/hideMessages/${hideMessageId}`);
      toast.success(i18n.t("hideMessages.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingHideMessage(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchHideMessages();
  };

  const handleDeleteAllHideMessages = async () => {
    try {
      await api.delete(`/hideMessages`);
      toast.success(i18n.t("hideMessages.toasts.deletedAll"));
    } catch (err) {
      toastError(err);
    }
    setDeletingAllHideMessages(null);
    setSearchParam("");
    setPageNumber();

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchHideMessages();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
       <ConfirmationModal
        title={
          deletingHideMessage ? `Você tem certeza que quer excluir esta Mensagem:`
          : `${i18n.t("hideMessages.confirmationModal.deleteAllTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => 
          deletingHideMessage ? handleDeleteHideMessage(deletingHideMessage.id)
         : handleDeleteAllHideMessages(deletingAllHideMessages)
        }
      >
        {
          deletingHideMessage ? `Essa ação é irreversível!`
            : `${i18n.t("hideMessages.confirmationModal.deleteAllMessage")}`
        }
      </ConfirmationModal>
      <HideMessagesModal
        open={hideMessageModalOpen}
        onClose={handleCloseHideMessageModal}
        reload={fetchHideMessages}
        aria-labelledby="form-dialog-title"
        hideMessageId={selectedHideMessage && selectedHideMessage.id}
      />
      <MainHeader>
        <Title >Mensagens para fechamento automático de tickets ({hideMessages.length})</Title>
        <MainHeaderButtonsWrapper>
          {/* <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="secondary" />
                </InputAdornment>
              ),
            }}
          /> */}
          <Tooltip title="Cadastrar Nova Mensagem">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenHideMessageModal}
            >
              <AddCircleOutline />
            </Button>
          </Tooltip>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Mensagem</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {hideMessages.map((hideMessage) => (
                <TableRow key={hideMessage.id}>
                  <TableCell align="center">
                    {hideMessage.message}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditHideMessage(hideMessage)}
                    >
                      <Edit color="secondary"/>
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingHideMessage(hideMessage);
                      }}
                    >
                      <DeleteOutline color="secondary"/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={2} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default HideMessages;
import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import {
	Formik,
	Form,
	Field
} from "formik";

import { 
	parseISO,
	format 
  } from "date-fns";
  

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { Colorize } from "@material-ui/icons";



const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
		width: '40vh'
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},

	colorAdorment: {
		width: 20,
		height: 20,
	},
	dates: {
		margin: 20,
		width: 50
	},
	customMsg: {
		width: '85vh'
	},
	position: {
		marginRight: theme.spacing(1),
		width: 150
	}
}));

const ClosedDaySchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required")
});

const ClosedDayModal = ({ open, onClose, closedDayId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		date: "",
		customMsg: null
	};
	const [closedDay, setClosedDay] = useState(initialState);


	//dates
	useEffect(() => {
		(async () => {
			if (!closedDayId) return;
			try {
				const { data } = await api.get(`/closedDays/${closedDayId}`);
				data.date = data.date.split('T')[0];
				setClosedDay(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setClosedDay({
				name: "",
				date: "",
				customMsg: null
			});
		};
	}, [closedDayId, open]);

	const handleClose = () => {
		onClose();
		setClosedDay(initialState);
	};
	// const applyDateFormat = value => {
	// 	// Aplicar a formatação de data ao valor
	// 	// Neste exemplo, formatamos a data para o formato dd/mm/yyyy
	// 	const formattedDate = value.replace(/\D/g, "").replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
	// 	return formattedDate;
	//   };

	const handleSaveClosedDay = async values => {
		values.date = values.date + ' 00:00:00'
		try {
			if (closedDayId) {
				await api.put(`/closedDays/${closedDayId}`, values);
			
			} else {
				await api.post("/closedDays", values);
			}
			toast.success(`${i18n.t("closedDayModal.notification.title")}`);
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{closedDayId
						? `${i18n.t("closedDayModal.title.edit")}`
						: `${i18n.t("closedDayModal.title.add")}` }
						
						
				</DialogTitle>
				<Formik
					initialValues={closedDay}
					enableReinitialize={true}
					validationSchema={ClosedDaySchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveClosedDay(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("closedDayModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<Field
									as={TextField}
									label={i18n.t("closedDayModal.form.date")}
									autoFocus
									name="date"
									type="date"
									error={touched.date && Boolean(errors.date)}
									helperText={touched.date && errors.date}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<Field
									as={TextField}
									label={i18n.t("closedDayModal.form.customMsg")}
									autoFocus
									multiline
									name="customMsg"
									error={touched.customMsg && Boolean(errors.customMsg)}
									helperText={touched.customMsg && errors.customMsg}
									variant="outlined"
									margin="dense"
									className={classes.customMsg}
								/>
								
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("closedDayModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{closedDayId
										? `${i18n.t("closedDayModal.buttons.okEdit")}`
										: `${i18n.t("closedDayModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ClosedDayModal;

import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import {
    Formik,
    Form,
    Field
} from "formik";
import { toast } from "react-toastify";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField
} from "@material-ui/core";


import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const HideMessageSchema = Yup.object().shape({
    message: Yup.string()
        .min(3, "Mensagem muito curta")
        .required("Obrigatório")
});

const HideMessageModal = ({ open, onClose, hideMessageId, reload }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);

    const initialState = {
        message: ""
    };

    const [hideMessage, setHideMessage] = useState(initialState);

    useEffect(() => {
        try {
            (async () => {
                if (!hideMessageId) return;

                const { data } = await api.get(`/hideMessages/${hideMessageId}`);
                console.log(data)
                setHideMessage(prevState => {
                    return { ...prevState, ...data };
                });
            })()
        } catch (err) {
            toastError(err);
        }
    }, [hideMessageId, open]);

    const handleClose = () => {
        setHideMessage(initialState);
        onClose();
    };

    const handleSaveHideMessage = async values => {
        const hideMessageData = { ...values, userId: user.id };
        try {
            if (hideMessageId) {
                await api.put(`/hideMessages/${hideMessageId}`, hideMessageData);
            } else {
                await api.post("/hideMessages", hideMessageData);
            }
            toast.success("Mensagem Cadastrada com sucesso!");
            if (typeof reload == 'function') {
                reload();
            }
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {(hideMessageId ? `Atualizar` : `Adicionar`)} Mensagem
                </DialogTitle>
                <Formik
                    initialValues={hideMessage}
                    enableReinitialize={true}
                    validationSchema={HideMessageSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveHideMessage(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        multiline
                                        label="Mensagem"
                                        name="message"
                                        error={touched.message && Boolean(errors.message)}
                                        helperText={touched.message && errors.message}
                                        variant="outlined"
                                        onChange={(e) => setHideMessage(prev => ({ ...prev, message: e.target.value }))}
                                        fullWidth
                                    />
                                </div>

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {hideMessageId
                                        ? `Editar`
                                        : `Adicionar`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default HideMessageModal;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const QueueOneSelect = ({ parentId, onChange }) => {
	const classes = useStyles();

	const [queues, setQueues] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/queue");
				data.push({ id: null, name: "Não possui Categoria Pai",})
				setQueues(data);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div style={{ marginTop: 6 }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>{i18n.t("queueOneSelect.inputLabel")}</InputLabel>
				<Select
					value={parentId}
					onChange={handleChange}
					renderValue={selected => {
						const selectedQueue = queues.find(queue => queue.id === selected);
						return (
								<MenuItem key={selectedQueue ? selectedQueue.id : null}>{selectedQueue ? selectedQueue.name : ''}</MenuItem>
						);
					}}
					label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
				>
					<MenuItem value={''}>&nbsp;</MenuItem>

					{queues.map((queue) => (

						<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default QueueOneSelect;

import React, { useEffect, useReducer, useState } from "react";

import openSocket from "../../services/socket-io";

import {
  AddCircleOutline,
  DeleteOutline,
  Edit
} from "@material-ui/icons";

import { 
  parseISO,
  format 
} from "date-fns";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from "@material-ui/core";

import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ClosedDayModal from "../../components/ClosedDayModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_CLOSED_DAYS") {
    const closedDays = action.payload;
    const newClosedDays = [];

    closedDays.forEach((closedDay) => {
      closedDay.date = format(parseISO(closedDay.date), "dd/MM/yyyy");
      const closedDayIndex = state.findIndex((q) => q.id === closedDay.id);
      if (closedDayIndex !== -1) {
        state[closedDayIndex] = closedDay;
      } else {
        newClosedDays.push(closedDay);
      }
    });

    return [...state, ...newClosedDays];
  }

  if (action.type === "UPDATE_CLOSED_DAYS") {
    const closedDay = action.payload;
    closedDay.date = format(parseISO(closedDay.date), "dd/MM/yyyy");
    const closedDayIndex = state.findIndex((u) => u.id === closedDay.id);

    if (closedDayIndex !== -1) {
      state[closedDayIndex] = closedDay;
      return [...state];
    } else {
      return [closedDay, ...state];
    }
  }

  if (action.type === "DELETE_CLOSED_DAYS") {
    const closedDayId = action.payload;
    const closedDayIndex = state.findIndex((q) => q.id === closedDayId);
    if (closedDayIndex !== -1) {
      state.splice(closedDayIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const ClosedDays = () => {
  const classes = useStyles();

  const [closedDays, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [closedDayModalOpen, setClosedDayModalOpen] = useState(false);
  const [selectedClosedDay, setSelectedClosedDay] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/closedDays");
        dispatch({ type: "LOAD_CLOSED_DAYS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket();
    socket.on("closedDay", (data) => {
      if (data.action === "update" || data.action === "create") {
    
        dispatch({ type: "UPDATE_CLOSED_DAYS", payload: data.closedDay });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CLOSED_DAYS", payload: data.closedDayId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenClosedDayModal = () => {
    setClosedDayModalOpen(true);
    setSelectedClosedDay(null);
  };

  const handleCloseClosedDayModal = () => {
    setClosedDayModalOpen(false);
    setSelectedClosedDay(null);
  };

  const handleEditClosedDay = (closedDay) => {
    setSelectedClosedDay(closedDay);
    setClosedDayModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedClosedDay(null);
  };

  const handleDeleteClosedDay = async (closedDayId) => {
    try {
      await api.delete(`/closedDays/${closedDayId}`);
      toast.success(i18n.t("closedDays.notifications.closedDayDeleted"));
    } catch (err) {
      toastError(err);
    }
    setSelectedClosedDay(null);
  };
  const [open, setOpen] = React.useState(false);

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedClosedDay &&
          `${i18n.t("closedDays.confirmationModal.deleteTitle")} ${selectedClosedDay.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteClosedDay(selectedClosedDay.id)}
      >
        {i18n.t("closedDays.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ClosedDayModal
        open={closedDayModalOpen}
        onClose={handleCloseClosedDayModal}
        closedDayId={selectedClosedDay?.id}
      />
      <MainHeader>
        <Title>{i18n.t("closedDays.title")} ({closedDays.length})</Title>
        <MainHeaderButtonsWrapper>
          <Tooltip title={i18n.t("closedDays.buttons.add")}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenClosedDayModal}
            >
              <AddCircleOutline />
            </Button>
          </Tooltip>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              {/* <TableCell>
         
              </TableCell> */}
              <TableCell align="center">
                {i18n.t("closedDays.table.id")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("closedDays.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("closedDays.table.date")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("closedDays.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {closedDays.map((closedDay) => (
                <TableRow key={closedDay.id}>
                  {/* <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell> */}
                  <TableCell align="center">{closedDay.id}</TableCell>
                  <TableCell align="center">{closedDay.name}</TableCell>
                  <TableCell align="center">{closedDay.date}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditClosedDay(closedDay)}
                    >
                      <Edit color="secondary" />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedClosedDay(closedDay);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default ClosedDays;
